import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next'

const CookieBar = () => {
  const { t } = useTranslation()
  return (
    <div className='fixed bottom-0 w-full bg-black'>
      <div className='container mx-auto px-4'>
        <CookieConsent
          location='bottom'
          buttonText={t('cookies_info_button')}
          cookieName='cookiesPolicyAgreement'
          style={{ background: '#000000', fontSize: '12px', position: 'relative' }}
          buttonStyle={{ background: '#ffffff', color: '#000000', fontSize: '13px', fontWeight: '700', padding: '.465em 2em' }}
          expires={365}
        >
          <Trans i18nKey='cookies_info'>
            This website uses cookies to provide necessary site functionality and improve your experience.
            By using our website, you agree to our <Link to='/privacy-policy' className='underline hover:no-underline'>Privacy & Cookies Policy</Link>
            and <Link to='/terms-of-service' className='underline hover:no-underline'>Terms of Service</Link>.
          </Trans>
        </CookieConsent>
      </div>
    </div>
  )
}

export default CookieBar
