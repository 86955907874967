// import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Logo from '../icons/logo.svg'
import Headroom from 'react-headroom'
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'

const { useState } = React

const Header = ({ siteTitle }) => {
  const [open, setOpen] = useState(true)
  const { language, languages, originalPath } = useI18next()

  const clickHandler = () => {
    setOpen(!open)
    const body = document.getElementsByTagName('body')[0]
    body.classList.toggle('overflow-hidden')
  }

  const handleScrollToSection = (e) => {
    setOpen(!open)
    const body = document.getElementsByTagName('body')[0]
    body.classList.remove('overflow-hidden')
  }

  const { t } = useTranslation()

  return (
    <Headroom>
      <header className='bg-white'>
        <div className='container mx-auto'>
          <nav className='flex items-center justify-between flex-wrap p-6'>
            <div className='flex items-center flex-shrink-0 text-white mr-6'>
              <Link to='/'>
                <Logo />
              </Link>
            </div>
            <div className='block lg:hidden z-50'>
              <button
                aria-label='menu'
                className={'flex flex-col items-center justify-center w-10 h-10 text-black relative outline-none focus:outline-none nav-toggle' + (open ? '' : ' is-open')}
                onClick={clickHandler}
              >
                <span className='transition duration-300 ease-in-out bg-current block h-px-2 w-8 my-1' aria-hidden='true' />
                <span className='transition duration-300 ease-in-out bg-current block h-px-2 w-8 my-1' aria-hidden='true' />
              </button>

            </div>
            <div className={`transition duration-300 ease-in-out transform -translate-x-full lg:translate-x-0 fixed lg:static top-0 left-0 z-10 min-h-screen lg:min-h-0 bg-black lg:bg-transparent w-full lg:items-center lg:w-auto navbar-menu ${open ? '' : 'is-open'}`}>
              <div className='min-h-screen lg:min-h-0 flex flex-col lg:flex-row justify-between lg:items-center p-8 lg:p-0 lg:text-sm lg:flex-grow'>
                <div className='mt-8 lg:mt-0'>
                  <Link
                    onClick={(e) => handleScrollToSection(e)}
                    to='/#feature-section'
                    className='transition duration-300 ease-in-out block mt-4 lg:inline-block text-white lg:text-black font-bold text-3xl lg:text-base lg:mt-0 lg:ml-12 hover:opacity-75'
                  >
                    {t('header.menu_items.01')}
                  </Link>
                  <Link
                    onClick={(e) => handleScrollToSection(e)}
                    to='/#pricing-section'
                    className='transition duration-300 ease-in-out block mt-4 lg:inline-block text-white lg:text-black font-bold text-3xl lg:text-base lg:mt-0 lg:ml-12 hover:opacity-75'
                  >
                    {t('header.menu_items.02')}
                  </Link>
                  <Link
                    onClick={(e) => handleScrollToSection(e)}
                    to='/#benefits-section'
                    className='transition duration-300 ease-in-out block mt-4 lg:inline-block text-white lg:text-black font-bold text-3xl lg:text-base lg:mt-0 lg:ml-12 hover:opacity-75'
                  >
                    {t('header.menu_items.03')}
                  </Link>
                </div>
                <div className='lg:ml-12'>
                  <a
                    href='https://app.booknowapp.co/'
                    className='transition duration-300 ease-in-out w-full lg:w-auto inline-block px-6 py-4 lg:p-0 mt-4 mr-4 text-sm lg:text-base text-center text-white lg:text-black font-bold leading-none border-solid border-2 border-blue-200 lg:border-0 lg:text-base lg:mt-0 lg:ml-8 lg:mr-0 hover:opacity-75'
                  >
                    {t('header.log_in')}
                  </a>
                  <a
                    href='https://app.booknowapp.co/sign-up'
                    className='transition duration-300 ease-in-out w-full lg:w-auto inline-block text-sm lg:text-base text-center font-bold leading-none text-black bg-white lg:bg-transparent border-solid border-2 border-white lg:border-dark hover:opacity-75 px-6 py-4 lg:py-3 mt-4 lg:mt-0 lg:ml-8'
                  >
                    {t('header.sign_up')}
                  </a>
                </div>
                <div className='relative flex items-center lg:mt-0 lg:ml-12 language__picker'>
                  <div className='selected__language uppercase text-white lg:text-black font-bold py-2 hidden lg:flex lg:items-center relative cursor-pointer'>
                    {language}
                  </div>
                  <ul className='languages flex lg:block lg:w-16 origin-top-right lg:absolute lg:right-0 lg:top-full lg:-mr-4 lg:shadow-lg lg:bg-white lg:hidden'>
                    {languages.map((lng) => (
                      <li key={lng} className='uppercase text-center'>
                        <Link to={originalPath} language={lng} className='text-white lg:text-black font-bold block px-4 py-3 lg:hover:bg-gray-25'>
                          {lng}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </Headroom>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ''
}

export default Header
