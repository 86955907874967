import React from 'react'
import FooterLogo from '../icons/logo-full-inverted.svg'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'

import FootImg from '../images/foot.svg'

const { useState } = React

const Footer = () => {
  const [open, setOpen] = useState(true)

  const handleScrollToSection = (e) => {
    setOpen(!open)
    const body = document.getElementsByTagName('body')[0]
    body.classList.remove('overflow-hidden')
  }

  const { t } = useTranslation()
  return (
    <footer className='py-8 lg:pt-24 bg-black relative lg:mt-56'>
      <div className='hidden lg:block px-4 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full mt-20'>
        <img src={FootImg} alt='BookNow' />
      </div>
      <div className='container mx-auto'>
        <div className='flex flex-wrap'>
          <div className='w-full lg:w-1/4 px-4 my-4 md:my-8'>
            <FooterLogo />
          </div>
          <div className='w-1/2 lg:w-1/4 px-4 my-4 md:my-8'>
            <ul>
              <li className='mb-5'>
                <Link
                  onClick={(e) => handleScrollToSection(e)}
                  to='/#feature-section'
                  className='text-white'
                >
                  {t('header.menu_items.01')}
                </Link>
              </li>
              <li className='mb-5'>
                <Link
                  onClick={(e) => handleScrollToSection(e)}
                  to='/#pricing-section'
                  className='text-white'
                >
                  {t('header.menu_items.02')}
                </Link>
              </li>
              <li className='mb-5'>
                <Link
                  onClick={(e) => handleScrollToSection(e)}
                  to='/#benefits-section'
                  className='text-white'
                >
                  {t('header.menu_items.03')}
                </Link>
              </li>
            </ul>
          </div>
          <div className='w-1/2 lg:w-1/4 px-4 my-4 md:my-8'>
            <ul>
              <li className='mb-5'>
                <Link to='/privacy-policy' className='text-white hover:opacity-75'>
                  {t('privacy_cookies_policy')}
                </Link>
              </li>
              <li className='mb-5'>
                <Link to='/terms-of-service' className='text-white hover:opacity-75'>
                  {t('terms_of_service')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className='text-center text-secondary'>
          <div className='mt-6 mb-8 text-sm text-white opacity-50'>
            {t('footer_copyrights')} {new Date().getFullYear()}. {t('footer_copyrights_txt')}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
